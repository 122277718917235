// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
import("./application.scss");

import "controllers";

import Rails from "@rails/ujs";
// import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage";
import "channels";

import "@lottiefiles/lottie-player";

import { loaderController } from "./scripts/LoaderController";
import { headerController } from "./scripts/HeaderController";
import { notyController } from "./scripts/NotyController";
import { selectController } from "./scripts/SelectController";
// import { searchController } from "./scripts/SearchController";
import { scrollActionsController } from "./scripts/ScrollActionsController";
import { productSliderController } from "./scripts/ProductSliderController";
import { productSwitchSliderController } from "./scripts/ProductSwitchSliderController";
import { lazyLoadController } from "./scripts/LazyLoadController";
import { cartSettings } from "./scripts/CartSettings";
import { orderController } from "./scripts/OrderController";
import { quantityController } from "./scripts/QuantityController";
import { modalController } from "./scripts/ModalController";
import { radioTabsController } from "./scripts/RadioTabsController";
import { filterController } from "./scripts/FilterController";
import { moreLessController } from "./scripts/MoreLessController";
import { rangeSliderController } from "./scripts/RangeSliderController";
import { heroSliderController } from "./scripts/HeroSliderController";
import { productGalleryController } from "./scripts/ProductGalleryController";
import { productGalleryVariantController } from "./scripts/ProductGalleryVariantController";
import { freeScrollSliderController } from "./scripts/FreeScrollSliderController";
import { validationController } from "./scripts/ValidationController";
import NewsletterController from "./scripts/NewsletterController";
import TableSearchController from "./scripts/TableSearchController";
import { showHideController } from "./scripts/ShowHideController";
import { tooltipController } from "./scripts/TooltipController";
import GMap from "./scripts/GMapOrderLocator";
import * as GMapLocator from "./scripts/GMapLocator";
import { accordionController } from "./scripts/AccordionController";
import { pageDescriptionController } from "./scripts/PageDescriptionController";

import { datepickerController } from "./scripts/DatepickerController";
import { passwordToggleController } from "./scripts/PasswordToggleController";
import { expandProductDetailsController } from "./scripts/ExpandProductDetailsController";

Rails.start();
// Turbolinks.start()
ActiveStorage.start();

window.addEventListener("load", (event) => {
  if (event.target === document) {
    load();
  }
});

window.addEventListener("load", (event) => {
  console.info("load event from" + event.target);
});

function load() {
  //action for page loader testing on dummy button in styleguide page
  const openLoaderBtn = document.querySelector(".js-open-loader");

  if (openLoaderBtn) {
    openLoaderBtn.addEventListener("click", () => {
      loaderController.start();
    });
  }

  accordionController.init();
  // window.alert("accordionController")
  radioTabsController.init();
  // window.alert("radioTabsController")
  modalController.init();
  // window.alert("modalController")
  lazyLoadController.init();
  // window.alert("lazyLoadController")
  headerController.init();
  // window.alert("headerController")
  cartSettings.init();
  // window.alert("cartSettings")
  quantityController.init();
  // window.alert("quantityController")
  orderController.init();
  // window.alert("orderController")
  filterController.init();
  // window.alert("filterController")
  moreLessController.init();
  // window.alert("moreLessController")
  rangeSliderController.init();
  // window.alert("rangeSliderController")
  heroSliderController.init();
  // window.alert("heroSliderController")
  productGalleryController.init();
  // window.alert("productGalleryController")
  freeScrollSliderController.init();
  // window.alert("freeScrollSliderController")
  passwordToggleController.init();
  // window.alert("passwordToggleController")
  datepickerController.init();
  // window.alert("datepickerController")
  validationController.init();
  // window.alert("validationController")
  productGalleryVariantController.init();
  // window.alert("productGalleryVariantController")
  showHideController.init();
  // window.alert("showHideController")
  pageDescriptionController.init();
  // window.alert("pageDescriptionController")
  expandProductDetailsController.init();
  // window.alert("expandProductDetailsController")

  // Sweet alert notification example
  let btnSuccess = document.querySelector(".js-noty-success");
  let btnError = document.querySelector(".js-noty-error");
  let btnInfo = document.querySelector(".js-noty-info");
  if (btnSuccess != null) {
    btnSuccess.addEventListener("click", () => {
      notyController.show("success", "Artikal je uspješno dodan u košaricu.");
    });
  }
  if (btnError != null) {
    btnError.addEventListener("click", () => {
      notyController.show(
        "error",
        "Greska kod azuriranja profila. Email vec postoji."
      );
    });
  }
  if (btnInfo != null) {
    btnInfo.addEventListener("click", () => {
      notyController.show("info", "Profil uspježno ažuriran");
    });
  }

  // Display backend notifications
  let msgInfo = document.querySelector(".msgInfo");

  if (msgInfo != null) {
    let messageType = msgInfo.dataset.success === "true" ? "success" : "info";

    notyController.show(messageType, msgInfo.dataset.text);
  }

  // Display backend notifications from js.erb
  const messageElement = document.getElementById("profile-notification");
  const displayMessage = (mutationsList, observeData) => {
    for (const mutation of mutationsList) {
      if (mutation.type === "attributes") {
        notyController.show("info", messageElement.dataset.notification);
      }
    }
  };

  if (messageElement != null) {
    const observeData = new MutationObserver(displayMessage);
    observeData.observe(messageElement, { attributes: true });
  }

  // custom select
  selectController.init();

  // tooltip
  tooltipController.init();

  productSliderController.init();
  productSwitchSliderController.init();

  // newsletter
  let newsletters = document.querySelectorAll(".js-newsletter");
  if (newsletters !== null) {
    newsletters.forEach((item) => {
      item = new NewsletterController(item);
      item.init();
    });
  }

  // searchable tables
  let ordersTable = document.getElementById("js-orders-list");
  if (ordersTable !== null) {
    ordersTable = new TableSearchController("js-orders-list");
    ordersTable.init();
  }

  /**
   * Init Order Locator Map on 'js-locator-order-map'
   * Add API Key
   */
  if (document.querySelector(".js-locator-order-map")) {
    let gmapOrder = new GMap(".js-locator-order-map", window.GOOGLE_MAPS_API);
    gmapOrder.init();
  }
  /**
   * Init Order Map on 'js-order-map' only apperas in styleguide as test
   * Add API Key
   */
  if (document.querySelector(".js-order-map")) {
    let gmap = new GMap(".js-order-map", window.GOOGLE_MAPS_API);
    gmap.init();
  }
  if (document.querySelector(".js-order-map-v2")) {
    let gmap = new GMap(".js-order-map-v2", window.GOOGLE_MAPS_API);
    gmap.init();
  }

  /**
   * Init Locator Map on 'js-locator-map'
   * Add API Key
   */
  if (document.querySelector(".js-locator-map")) {
    GMapLocator.GMap(".js-locator-map", window.GOOGLE_MAPS_API);
  }

  // hide disclaimer on scroll
  scrollActionsController.hide(".js-hide-on-scroll");

  // search autocomplete
  // if (document.querySelector(".js-sitesearch") != null) {
  //   searchController.siteSearch(".js-sitesearch");
  // }
  // if (document.querySelector(".js-autocomplete") != null) {
  //   searchController.siteSearch(".js-autocomplete");
  // }
}
